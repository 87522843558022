
.cartSec{
      width: 100%;
      min-width: 200px;
      height: 60px;
      display: none;
      border-radius: 3px;
      box-shadow: 0 0 3px grey;
      }
      .cartSec{
            display: flex;
      }
      .cartSec div:hover{
      
            box-shadow: 0 0 6px grey;
      }


.prodBodyItemBtn {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      /* bottom: 0px; */
      /* border: 1px solid red; */
      display: flex;
      align-items: center;
      justify-content: center;
}
.like{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      height: 100%;
}
.addtocart{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 100%;
      background-color: grey;
      cursor: pointer;
      /* font-size: .5em !important; */
}

