.form-field input{

    border: 1px solid gray;
    margin-bottom: 10px;
    background-color: transparent;


}
.form-field{
    width:70%;
}
.card-btn:hover{
    background-color: #e9ecef;

}
.sec2{
    width:40%;
    height:40%
}
.a-link:hover{
    color: cyan;

}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .checkout{
        flex-direction: column;

    }
    .form-field{
        width: 100%;

    }
   .sec2{
       width: 100%;

   }
    
}
