*{
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  transition: .4s;
  
}
a{
  text-decoration: none !important;
}
del{
  text-decoration: line-through !important;
  margin-left: 4px;
}
.App{
  position: relative;
}

