.container{
      width: 100%;
      height: 100%;
      background-color: rgba(128, 0, 128, 0.169);
      border:2px solid red;
      position: relative;
      overflow: hidden;

}
.previewBtn{
      position:absolute;
      top: 10px;
      right: 5px;
      width: 50px;
      text-align: center;
      color: red;
      border: 2px solid red;
      z-index: 2;

}.previewBtn:hover{
      color: white;
      background-color: red;
      /* border: 2px solid white; */
}
.previewBtn2{
      position:absolute;
      top: 10px;
      right: 110px;
      width: 50px;
      text-align: center;
      color: red;
      border: 2px solid red;
      z-index: 2;

}.previewBtn2:hover{
      color: white;
      background-color: red;
      /* border: 2px solid white; */
}
.box{
      width: 300px;
      height: 300px;
      border: 2px solid black;
      background-color: yellow;
}
.inputHolder{
      width: 100%;

}
.form{
      font-size: .9em !important;
}
.imageHolder{
      width: 300px;
      height: 150px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      border: 1px solid brown;
}
.imageHolder img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
}
.imageHolder input{
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
}
.smallImages{
      position: relative;
      width: 100%;
      display: flex;
      margin-bottom: 20px;
}
.smallImage{
      position: relative;
      width: 150px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid brown;
}
.smallImage img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
}
.smallImage input{
      
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
}
.previewHide{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      top:100%;
}
.preview{
      transition:.8s;
      background-color: rgba(48, 4, 48, 0.852);
      width: 100%;
      height: 100%;
      position: absolute;
      top:0%
}
.submitbtn{
      position: relative;
      width:fit-content;
      background-color: inherit;
}
