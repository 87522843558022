
/* --- brand design */
.brandContainer{
      width: 280px;
      height: 250px;
      border-radius: 6px;
      overflow: hidden;
}
.brandImg{
      width: 100%;
      height: 70%;
}
.brandImg img{
      width: 100%;
      height: 100%;
}
.brandDesc{
      font-size: 1em;
      width: 100%;
      height: 30%;
}
.brandDescTitle{
      font-weight: 900;
      font-size: 1.1em;
}
.brandDescRemark{
      font-weight: 600;
}