
/* .container{
      background-color: var(--bg-primary);
} */

.dropdownn {
      position: relative;
      display: inline-block;
  }
  
  .dropdownn-content {
      display: none;
      position: absolute;
      background-color: #15161D;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
  }
  
  .dropdownn-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
  }
  
  .dropdownn-content a:hover {background-color: #15161D}
  
  .dropdownn:hover .dropdownn-content {
      display: block;
  }
  
  
  
                        #navigation {
                        background: #FFFFFF;  /* fallback for old browsers */
                        background: #293e15;/*-webkit-linear-gradient(to right, #F9D423, #FF4E50);   Chrome 10-25, Safari 5.1-6 */
                        background: #293e15; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


          
        }
                        #header {

                        background: #780206;  /* fallback for old browsers */
                        background: #FFFFFF;/*-webkit-linear-gradient(to right, #061161, #780206);   Chrome 10-25, Safari 5.1-6 */
                        background: #FFFFFF;/*linear-gradient(to right, #061161, #780206); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  
        }
                        #top-header {


                            background: #FFFFFF;  /* fallback for old browsers */
                            background: #293e15;/*-webkit-linear-gradient(to right, #F9D423, #FF4E50);   Chrome 10-25, Safari 5.1-6 */
                            background: #293e15;

        }
                        #footer {
                          background: #7474BF;  /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #348AC7, #7474BF);  /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #348AC7, #7474BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


                        color: #1E1F29;
        }
                        #bottom-footer {
                          background: #7474BF;  /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #348AC7, #7474BF);  /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #348AC7, #7474BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
          

        }
                        .footer-links li a {
                          color: #1E1F29;
        }
                        .mainn-raised {

                          margin: -7px 0px 0px;
                        border-radius: 6px;
                        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

        }

                        .glyphicon{
                          display: inline-block;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
    }
                        .glyphicon-chevron-left:before{
                          content:"\f053"
    }
                        .glyphicon-chevron-right:before{
                          content:"\f054"
    }



