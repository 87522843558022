.prodContainer {
      position: relative;
      width: 280px;
      height: 490px !important;
      border-radius: 7px;
      overflow: hidden;
      box-shadow: 0px 0px 3px rgb(95, 92, 92);
}

.prodImage {
      width: 50%;
      height: 100%;
      display: flex;
      /* border: 1px solid red; */
      /* align-items: center !important; */

}

.prodImage img {
      /* border: 1px solid red; */
      width: 100%;
      height: 100%;
}

.prodBody {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* height: 100%; */
      height: 100%;
      width: 100%;
      flex-direction: column;
      font-weight: 600;
      color: rgb(54, 51, 51);
      /* border: 1px solid gold; */
      padding: 0px !important;
      align-items: flex-start;

}
.priseSec{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
}
.cartSec{
width: 100%;
height: 60px;
display: none;
box-shadow: 0 0 3px grey;
}
.prodContainer:hover .cartSec{
      display: flex;
}
.cartSec div:hover{

      box-shadow: 0 0 6px grey;
}
.prodBodyItem {
      width: 100%;
      text-align: center;
}

.prodBodyItemTitle {
      width: 100%;
      font-size: large;
      text-align: center;
}

.prodBodyItemPrice {
      width: 100%;
      text-align: center;
}

.prodBodyItemRating {
      width: 100%;
      text-align: center;
}

