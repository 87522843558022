:root {
      --f1: .9em;
      --f2: 1em;
      --f3: 1.1em;
      --f4: 1.2em;
      --f5: 1.3em;
      --h1: .9em;
      --h2: 1em;
      --h3: 1.2em;
      --h4: 1.5em;
      --h5: 1.7em;
}



.f1{
      font-size: var(--f1);
}
.f2{
      font-size: var(--f2);
}
.f3{
      font-size: var(--f3);
}
.f4{
      font-size: var(--f4);
}
.f5{
      font-size: var(--f5);
}

.h1{
      font-size: var(--h1);
}
.h2{
      font-size: var(--h2);
}
.h3{
      font-size: var(--h3);
}
.h4{
      font-size: var(--h4);
}
.h5{
      font-size: var(--h5);
}