.loaderContainer{
      position: absolute;
      top: 0;
      background-color: rgba(14, 0, 0, 0.384);
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 10;
      align-items: center;
      justify-content: center;
      /* border: 2px solid red; */
}