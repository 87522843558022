.boxesContainer{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
}


.box{
      position: absolute;
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      top: 45%;
}
.box1{
      left: 20%;
      background-color: #c4a598e0 !important;
}
.box2{
      left: 30%;
      background-color: #c8b7e7e0;
}
.box3{
      left: 40%;
      background-color: #F28705e0;
}
.box4{
      left: 50%;
      background-color: #A6035De0;
}
.box5{
      left: 60%;
      background-color: #D9042Be0;
}
