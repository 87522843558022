.adminContainer{
      width: 100%;
      min-height: 100vh;
      background-color: #e1e1e1;
}
.adminHeader{
      position: relative;
      width: 100%;
      text-align: center;
      min-height: 80px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5em;
}
.logoutbtn{
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: inherit;
      color: #212121 ;
      margin-bottom: 10px;
}

.adminBody{
      width: 100%;
      display: flex;
}
.adminSideBar{
      width: 30vw ;
      min-width: 160px;
      background-color: rgba(0, 0, 0, 0.059);
}
.adminContent{
      width: 100%;
      background-color: #ffffff;
}
.sideBarHeader{
      font-size: 1em;
      width: 100%;
      text-align: left;

}
.green{
      background-color: green;
}
.sideItem{
      min-width: 150px;
      width: 80%;
      color: rgba(140, 137, 137, 0.381);
      font-size: 0.8em;
      background-color: rgba(128, 0, 128, 0.811);
      border-radius: 8px;
      text-align: center;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
      margin: 5px;
}
.sideItem:hover{
      background-color: rgb(72, 1, 72);
}
.dropdown{
      width: 100%;
      font-size: .8em;
      color: white;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
}
.dropdownItem{
      border-radius: 4px;
      background-color: rgba(128, 0, 128, 0.811);
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
}

.dropdownItem:hover{
      background-color: rgb(72, 1, 72);
}
.active{
      /* background-color: red; */
      color: white !important;
      background-color: #293e15 !important;
}

@media screen and (max-width:700px) {
      /* .adminSideBar{
            display: none;
            width: 0px;
      } */
      .adminBody{
            display: block;
      }
      .adminSideBar{
            width: 100%;
      }
}